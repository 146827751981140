import React from 'react';
import copyright from '../asset/copyright.png';

const Footer = () => {
    return (
        <footer 
            className="text-white py-6" 
            style={{ 
                backgroundColor: 'rgba(12, 24, 38, 1)', 
                borderTop: '1px solid white', 
                borderBottom: '1px solid white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <div className="container mx-auto text-center">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={`${copyright}`} alt='copyright' className='w-3 h-3 mr-2'/> 
                    <p className="text-sm">
                        {new Date().getFullYear()} M Bahrul Qolbi. All Right Reserved
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
