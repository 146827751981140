import React from 'react';

import Pa from '../asset/project_A.png';
import Pb from '../asset/project_F.png';
import Pc from '../asset/project_C.png';
import Pd from '../asset/project_D.png';
import Pe from '../asset/project_E.png';
import Pf from '../asset/project_B.png';
import Pg from '../asset/project_G1.jpeg';
import Ph from '../asset/project_H.png';
import Pi from '../asset/project_I.png';

const Projects = () => {
    return (
        <section id='projects' className="py-20" style={{ backgroundColor: 'rgba(12, 24, 38, 1)' }}>
            <div className="container mx-auto text-center">
                <h2 className="text-4xl font-semibold mb-10 text-white">My Projects</h2>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* Project 1 */}
                    <div className="bg-slate-700 p-4 rounded mb-8 hover:bg-slate-500 transition-colors duration-300" style={{
                        boxShadow: '0px 0px 15px 5px rgba(43, 59, 78, 1)',
                    }}>
                        <img src={Pa} alt="Project 1" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2 text-white">Home web with Tailwind</h3>
                        <p className="text-white">A website with using Tailwind CSS</p>
                    </div>

                    {/* Project 2 */}
                    <div className="bg-slate-700 p-4 rounded mb-8 hover:bg-slate-500 transition-colors duration-300" style={{
                        boxShadow: '0px 0px 15px 5px rgba(43, 59, 78, 1)',
                    }}>
                        <img src={Pb} alt="Project 2" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2 text-white">Music Website</h3>
                        <p className="text-white">Website for Music Promotion</p>
                    </div>

                    {/* Project 3 */}
                    <div className="bg-slate-700 p-4 rounded mb-8 hover:bg-slate-500 transition-colors duration-300" style={{
                        boxShadow: '0px 0px 15px 5px rgba(43, 59, 78, 1)',
                    }}>
                        <img src={Pc} alt="Project 3" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2 text-white">Gif Generator</h3>
                        <p className="text-white">Website for searching Gif</p>
                    </div>

                    {/* Project 4 */}
                    <div className="bg-slate-700 p-4 rounded mb-8 hover:bg-slate-500 transition-colors duration-300" style={{
                        boxShadow: '0px 0px 15px 5px rgba(43, 59, 78, 1)',
                    }}>
                        <img src={Pd} alt="Project 4" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2 text-white">Website Magang SMK N 3 Kendal</h3>
                        <p className="text-white">A website for helping student</p>
                    </div>

                    {/* Project 5 */}
                    <div className="bg-slate-700 p-4 rounded mb-8 hover:bg-slate-500 transition-colors duration-300" style={{
                        boxShadow: '0px 0px 15px 5px rgba(43, 59, 78, 1)',
                    }}>
                        <img src={Pe} alt="Project 5" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2 text-white">Login Design Figma</h3>
                        <p className="text-white">Prototyping Login using figma.</p>
                    </div>

                    {/* Project 6 */}
                    <div className="bg-slate-700 p-4 rounded mb-8 hover:bg-slate-500 transition-colors duration-300" style={{
                        boxShadow: '0px 0px 15px 5px rgba(43, 59, 78, 1)',
                    }}>
                        <img src={Pf} alt="Project 6" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2 text-white">Rental Dekstop App</h3>
                        <p className="text-white">A App desktop that helping someone for searching Rental.</p>
                    </div>

                    {/* Project 7 */}
                    <div className="bg-slate-700 p-4 rounded mb-8 hover:bg-slate-500 transition-colors duration-300" style={{
                        boxShadow: '0px 0px 15px 5px rgba(43, 59, 78, 1)',
                    }}>
                        <img src={Pg} alt="Project 7" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2 text-white">Weather App</h3>
                        <p className="text-white">A Website that should searching weather arround.</p>
                    </div>  

                    {/* Project 8 */}
                    <div className="bg-slate-700 p-4 rounded mb-8 hover:bg-slate-500 transition-colors duration-300" style={{
                        boxShadow: '0px 0px 15px 5px rgba(43, 59, 78, 1)',
                    }}>
                        <img src={Ph} alt="Project 8" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2 text-white">Laundry Website</h3>
                        <p className="text-white">A Website that should control laundry orders.</p>
                    </div>

                    {/* Project 9 */}
                    <div className="bg-slate-700 p-4 rounded mb-8 hover:bg-slate-500 transition-colors duration-300" style={{
                        boxShadow: '0px 0px 15px 5px rgba(43, 59, 78, 1)',
                    }}>
                        <img src={Pi} alt="Project 9" className="w-full h-48 object-cover mb-4 rounded"/>
                        <h3 className="text-2xl font-bold mb-2 text-white">Sell and Buy Design App</h3>
                        <p className="text-white">A Design for App Mobile with Look that fresh.</p>
                    </div>
                </div> 
            </div>
        </section>
    )
}

export default Projects;
