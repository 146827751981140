import React from 'react';

const Navbar = () => {
  return (
    <nav className="fixed w-full bg-black bg-opacity-15 text-white p-4 ">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-white text-3xl font-bold ml-6">M Bahrul Qolbi</h1>
          <ul className="flex space-x-6 mr-3">
            <li><a href="#about" className="hover:text-red-500 mx-2">About</a></li>
            <li><a href="#projects" className="hover:text-red-500 mx-2">Projects</a></li>
            <li><a href="#contact" className="hover:text-red-500 mx-2">Contact</a></li>
          </ul>
        </div>
      </nav>
  );
}

export default Navbar;
