import React from 'react';
import cover from '../asset/cover.jpg';

const Hero = () => {
    return (
        <>
            <section className="bg-cover bg-center h-screen text-white" style={{ backgroundImage: `url(${cover})`, margin: '0', padding: '0' }}>
                <div className="bg-black bg-opacity-75 h-full flex flex-col justify-center items-center text-center p-6">
                    <div className="flex items-baseline space-x-2 mb-4">
                        <h2 className="text-5xl font-bold">Hello,</h2>
                        <h2 className="text-5xl font-bold text-red-600"> I am</h2>
                        <p className="text-5xl font-bold text-red-600">Bahrul</p>
                    </div>
                    <p className="text-2xl mb-8">Student & Software Engineer</p>
                    <a href="#projects" className="bg-rose-700 text-white font-bold py-2 px-6 rounded-3xl transition duration-300"
                        style={{
                            boxShadow: '0px 0px 15px 5px rgba(215, 47, 47, 0.8)',
                        }}>
                        My Projects
                    </a>
                </div>
            </section>
        </>
    );
}

export default Hero;
