import React from 'react'

const Contact = () => {
  return (
    <section id="contact" className="py-20" style={{ backgroundColor: 'rgba(12, 24, 38, 1)', borderTop: '1px solid white' }}>
        <div className="container mx-auto px-6 text-center">
            <h2 className="text-4xl font-semibold mb-2 text-white">Contact Me</h2>
            <p className="text-xl text-white">
                Feel Free to leave me a message
            </p>
            <p className="text-xl mb-5 text-red-600">
                Mqolbishufi@gmail.com
            </p>
            <a href="mailto:bahrulqolbi17@gmail.com" style={{
                       boxShadow: '0px 0px 15px 5px rgba(215, 47, 47, 0.8)',
                   }} className="bg-rose-700 hover:bg-rose-500 text-white font-bold py-2 rounded-3xl shadow-md transition duration-300 p-4">Send mail</a>
        </div>
    </section>
  )
}

export default Contact;